import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="fKXMkX-dKX0" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <React.Fragment>
        <p>
          風にさそわれて、森に足を踏み入れる。<br />
          するとそこには、私たちが知らなかった<br />
          森の表情がつぎつぎとあらわれる。<br />
          あなただけに届く声。あなただけが感じるいのちの躍動。<br />
          さあ、行こう。<br />
          風にのせて永遠の年月のなかで繰りひろげられる、いのちの冒険へ。<br />
          まだ誰も知らない森が、あなたを待っている。
        </p>
        <dl className="dlWorkCredit mb-0">
          <dt>Producer：</dt>
          <dd>大田俊吾（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit mb-0">
          <dt>Director：</dt>
          <dd>落合正夫（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit mb-0">
          <dt>Cinematographer：</dt>
          <dd>小久保尚志（MONTAGE Inc.）</dd>
        </dl>
        <dl className="dlWorkCredit">
          <dt>CG/VFX：</dt>
          <dd>石川陽介・榊原陽和（MONTAGE Inc.）</dd>
        </dl>
      </React.Fragment>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Art Direction', 'Video Production'],
    awards: [],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(1, 15)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/sumitomo_teaser"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/sumitomo_teaser.*"}) {
      publicURL
    }
  }
`;
